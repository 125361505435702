<template>
  <div>
    <div class="search">
      <div class="title">景区列表</div>
      <hr />
      <el-form :inline="true" class="top">
        <el-form-item label="景区名称:">
          <el-input v-model="params.sceneryName"></el-input>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="getList(1)">查询</el-button>
          <el-button type="primary" @click="add">添加</el-button>
          <!-- <el-button type="primary" @click="reset">重置</el-button> -->
        </el-form-item>
      </el-form>
    </div>
    <el-table
      :data="tableData"
      border
      tooltip-effect="dark"
      :row-key="rowKey"
      style="width: 100%"
      align="center"
    >
      <!-- <el-table-column type="selection" width="55" :reserve-selection="true" align="center"> </el-table-column> -->
      <el-table-column label="排序" align="center" width="80">
        <template slot-scope="scope">
          <el-input v-model="scope.row.dept" @change="changeSort(scope.row)"></el-input>
        </template>
      </el-table-column>
      <el-table-column prop="sceneryName" label="景区名称" align="center">
      </el-table-column>

      <el-table-column prop="strStatus" label="状态" width="200" align="center">
      </el-table-column>

      <el-table-column prop="addTime" label="添加时间" width="200" align="center">
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button
            type="success"
            size="mini"
            @click="upData(scope.row, 1)"
            v-if="scope.row.status == 2"
            >上架</el-button
          >
          <el-button
            type="warning"
            size="mini"
            @click="upData(scope.row, 2)"
            v-if="scope.row.status == 1"
            >下架</el-button
          >
          <el-button type="primary" size="mini" @click="remove(scope.row)"
            >删除</el-button
          >
          <el-button type="primary" size="mini" @click="edit(scope.row)">编辑</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="block">
      <!-- <div>
              <el-button type="primary" @click="allCheckVip">批量修改vip</el-button>
            <el-button type="primary" @click="allOpe">批量操作</el-button>
         </div> -->
      <div>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="params.currentPage"
          :page-sizes="[5, 10, 20]"
          :page-size="params.pageSize"
          layout="total,sizes,prev,pager,next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="30%"
      :close-on-click-modal="false"
    >
      <el-form :model="addList" :rules="addRules" ref="addRuleFormRef">
        <el-form-item label="景区名称:">
          <el-input v-model="addList.sceneryName"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  scenicList,
  scenicListAdd,
  scenicListUpdata,
  scenicListSort,
  scenicListDel
} from "../../api/food.js";
export default {
  data() {
    return {
      dialogVisible: false,
      total: 0,
      params: {
        currentPage: 1,
        pageSize: 5,
        sceneryName: "",
      },
      addList: {
        sceneryId: 0,
        sceneryName: "",
      },
      addRules: {
        sceneryName: [{ required: true, message: "请输入景区名称", trigger: "blur" }],
      },
      tableData: [],
      date: null,
      pickerOptions0: {
        disabledDate(time) {
          return time.getTime() > Date.now() - 8.64e6;
        },
      },
    };
  },
  created() {
    this.getList();
  },
  methods: {
    rowKey(row) {
      return row.userId;
    },
    async getList(page = "") {
      if (page) {
        this.params.currentPage = page;
      }
      //   this.params.beginTime = this.date ? this.date[0] : "";
      //   this.params.endTime = this.date ? this.date[1] : "";
      const { data } = await scenicList(this.params);
      if (data.code == 0) {
        this.tableData = data.list;
        this.total = data.pagination.total;
      } else {
      }
    },
    handleSizeChange(e) {
      this.params.pageSize = e;
      this.getList();
    },
    // 页数改变
    handleCurrentChange(e) {
      this.params.currentPage = e;
      this.getList();
    },
    reset() {
      this.params.currentPage = 1;
      this.params.pageSize = 5;
      this.params.phone = "";
      this.params.beginTime = "";
      this.params.endTime = "";
      this.date = null;
      this.getList();
    },
    add() {
      this.addList.sceneryName = "";
      this.addList.sceneryId = 0;

      this.dialogVisible = true;
    },
    remove(row) {
      this.$confirm("此操作将删除该景区, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { data } = await scenicListDel({
            sceneryId: row.sceneryId,
            status: 3,
          });
          if (data.code == 0) {
            this.$message.success("删除成功");
            this.getList();
          } else {
            this.$message.warning(data.msg);
          }
        })
        .catch(() => {
       
        });
    },
    edit(row) {
      this.addList.sceneryName = row.sceneryName;
      this.addList.sceneryId = row.sceneryId;
      this.dialogVisible = true;
    },
    changeSort(row) {
      scenicListSort({
        dept: row.dept,
        sceneryId: row.sceneryId,
      }).then((res) => {
        if (res.data.code == 0) {
          this.$message.success("设置成功");
          this.getList();
        } else {
          this.$message.warning(res.data.msg);
        }
      });
    },
    upData(row, type) {
      this.$confirm(`此操作将${type == 1 ? "上架" : "下架"}景区, 是否继续?, 提示`, {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { data } = await scenicListUpdata({
            sceneryId: row.sceneryId,
            status: type,
          });
          if (data.code == 0) {
            this.$message({
              type: "success",
              message: data.msg,
            });
            this.getList();
          } else {
            this.$message({
              type: "warning",
              message: data.msg,
            });
          }
        })
        .catch(() => {});
    },
    submit() {
      this.$refs.addRuleFormRef.validate(async (valid) => {
        if (valid) {
          if (this.loading) return;
          this.loading = true;
          const { data } = await scenicListAdd(this.addList);
          this.loading = false;
          if (data.code == 0) {
            this.$message({
              message: "添加成功",
              type: "success",
            });
            this.dialogVisible = false;
            this.getList();
          } else {
            this.$message({
              message: data.msg,
              type: "warning",
            });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style scoped>
.title {
  padding: 10px 0;
}
.top {
  margin-top: 20px;
}
.block {
  margin-top: 15px;
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
</style>
